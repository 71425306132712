import request from '@/utils/request'

export function getList(params) {
  return request({
    url: 'api/jz/custom/list',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: 'api/jz/custom',
    method: 'post',
    data
  })
}


export function del(ids) {
  return request({
    url: 'api/jz/custom',
    method: 'delete',
    data: ids
  })
}


export function edit(data) {
  return request({
    url: 'api/jz/custom',
    method: 'put',
    data
  })
}

export function getOne(id){
  return request({
    url: 'api/jz/custom/'+id,
    method: 'get'
  })
}

export function getSelectOption(type){
  return request({
    url: 'api/award/project/opt/'+type,
    method: 'get'
  })
}

export default { add, del,edit ,getSelectOption}

